import { FC, useMemo } from 'react';
import Tooltip from '../shared/Tooltip';
import DistributeIcon from '../shared/icon/DistributeIcon';
import { ProfileImageStack, ImageSize } from '../ownership/ProfileImageStack';
import { DistributionResponse, DistributionMemberStatus, DistrubutiuonSchedulingType, DistributionStatus } from '../../models/Distribution';
import CheckIcon from '../shared/icon/CheckIcon';
import { useTranslation } from 'react-i18next';
import DateUtils from '../../utils/DateUtils';
import { useCurrentUser } from '../../global-state/Auth';

type Props = { className?: string; distribution: DistributionResponse };

const DistributionStatusTag: FC<Props> = (props) => {
  const { className, distribution } = props;
  const user = useCurrentUser((x) => x.value);
  const { t } = useTranslation(['distribution']);

  const statusColor = useMemo(() => {
    return distribution.status === DistributionStatus.InActive ? 'text-gray-1' : 'text-semantic-3';
  }, [distribution.status]);

  const isForCurrentUser = useMemo(() => distribution.members.some((m) => m.memberId === user?.id), [distribution.members, user]);

  const didCurrentUserAcknowledge = useMemo(() => {
    if (isForCurrentUser) {
      return distribution.members.some(
        (m) => m.memberId === user?.id && (m.status === DistributionMemberStatus.Acknowledged || m.status === DistributionMemberStatus.Signed),
      );
    }
  }, [distribution.members, isForCurrentUser, user?.id]);

  const tooltipText = useMemo(() => {
    let title = '';
    let description = '';

    switch (distribution.status) {
      case DistributionStatus.InActive:
        title = t('status-tooltips.titles.in-active');
        description =
          (distribution.scheduleType === DistrubutiuonSchedulingType.FixedDate && distribution.startDate) ||
          (distribution.scheduleType === DistrubutiuonSchedulingType.EffectiveDate && distribution.startDate)
            ? t('status-tooltips.descriptions.in-active.date', {
                date: DateUtils.formatDate(new Date(distribution.startDate)),
              })
            : t('status-tooltips.descriptions.in-active.immediate');
        break;
      case DistributionStatus.Active:
      case DistributionStatus.Completed:
        if (
          distribution.members.length > 0 &&
          distribution.members.every((m) => m.status === DistributionMemberStatus.Acknowledged || m.status === DistributionMemberStatus.Signed)
        ) {
          title = t('status-tooltips.titles.completed');
          description = t('status-tooltips.descriptions.completed', { date: DateUtils.maxDateTime(distribution.members.map((m) => m.statusUtc)) });
        } else {
          title = t('status-tooltips.titles.active');
          description = !isForCurrentUser
            ? t('distribution:status-tooltips.descriptions.active.no-action')
            : didCurrentUserAcknowledge
              ? t('distribution:status-tooltips.descriptions.active.acknowledged', {
                  date: DateUtils.formatDateTime(new Date(distribution.members.find((x) => x.memberId === user?.id)!.statusUtc)),
                })
              : t('distribution:status-tooltips.descriptions.active.action-require');
        }
        break;
      default:
        break;
    }

    return (
      <>
        <div className="font-medium">{title}</div>
        <span>{description}</span>
      </>
    );
  }, [
    didCurrentUserAcknowledge,
    distribution.members,
    distribution.scheduleType,
    distribution.startDate,
    distribution.status,
    isForCurrentUser,
    t,
    user?.id,
  ]);

  return (
    <Tooltip text={tooltipText}>
      {(tooltip) => (
        <div
          {...tooltip}
          className={`${className} bg-gray-6 ml-2 flex min-h-[34px] min-w-[34px] flex-shrink-0 items-center justify-center gap-2 rounded-md p-1`}
        >
          <DistributeIcon className={`${statusColor} h-5 w-5 flex-shrink-0`} />
          {isForCurrentUser &&
            user &&
            (didCurrentUserAcknowledge ? <CheckIcon className="h-4 w-4" /> : <ProfileImageStack users={[user]} size={ImageSize.XS} />)}
        </div>
      )}
    </Tooltip>
  );
};

export default DistributionStatusTag;
